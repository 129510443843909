import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/index.css';

const Logo = () => {
    const shade = { fill: '#4093D0' };
    const color = { fill: '#FFFFFF' };
    const svgStyle = { enableBackground: 'new 0 0 893.76 176.67' };
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 893.76 176.67" style={ svgStyle }>
            <g>
	            <g>
		            <path style={ shade } d="M811.68,12.52c-45.33,0-82.08,36.75-82.08,82.08s36.75,82.08,82.08,82.08c45.33,0,82.08-36.75,82.08-82.08 S857.01,12.52,811.68,12.52z M811.68,131.01c-20.11,0-36.42-16.3-36.42-36.42c0-20.11,16.3-36.42,36.42-36.42 c20.11,0,36.42,16.3,36.42,36.42C848.1,114.71,831.8,131.01,811.68,131.01z" />
                    <path style={ shade } d="M227.83,81.01c0-37.82-30.66-68.49-68.49-68.49c-17.45,0-33.33,6.58-45.43,17.33 C101.82,19.1,85.94,12.52,68.49,12.52C30.66,12.52,0,43.18,0,81.01v26.3v30.05v16.48c0,12.61,10.22,22.83,22.83,22.83 s22.83-10.22,22.83-22.83v-16.48v-30.05v-26.3c0-12.61,10.22-22.83,22.83-22.83S91.32,68.4,91.32,81.01v26.3v30.05v16.48 c0,12.61,10.22,22.83,22.83,22.83s22.83-10.22,22.83-22.83v-16.48v-30.05v-26.3c0-12.61,10.22-22.83,22.83-22.83 c12.61,0,22.83,10.22,22.83,22.83v24.02c-0.08,0.76-0.23,1.5-0.23,2.29v46.53c0,12.61,10.22,22.83,22.83,22.83 c12.61,0,22.83-10.22,22.83-22.83v-46.53c0-0.78-0.15-1.52-0.23-2.29V81.01z" />
                    <path style={ shade } d="M592.52,81.01c0-37.82-30.66-68.49-68.49-68.49c-17.45,0-33.33,6.58-45.43,17.33 c-12.09-10.75-27.97-17.33-45.43-17.33c-37.82,0-68.49,30.66-68.49,68.49v26.3v30.05v16.48c0,12.61,10.22,22.83,22.83,22.83 c12.61,0,22.83-10.22,22.83-22.83v-16.48v-30.05v-26.3c0-12.61,10.22-22.83,22.83-22.83c12.61,0,22.83,10.22,22.83,22.83v26.3 v30.05v16.48c0,12.61,10.22,22.83,22.83,22.83c12.61,0,22.83-10.22,22.83-22.83v-16.48v-30.05v-26.3 c0-12.61,10.22-22.83,22.83-22.83s22.83,10.22,22.83,22.83v24.02c-0.08,0.76-0.23,1.5-0.23,2.29v46.53 c0,12.61,10.22,22.83,22.83,22.83s22.83-10.22,22.83-22.83v-46.53c0-0.78-0.15-1.52-0.23-2.29V81.01z" />
                    <path style={ shade } d="M661.12,81.01c-12.61,0-22.83,10.22-22.83,22.83v50.01c0,12.61,10.22,22.83,22.83,22.83 s22.83-10.22,22.83-22.83v-50.01C683.95,91.23,673.73,81.01,661.12,81.01z" />
                    <circle style={ shade } cx="661.18" cy="35.35" r="22.83" />
                    <path style={ shade } d="M296.26,81.01c-12.61,0-22.83,10.22-22.83,22.83v50.01c0,12.61,10.22,22.83,22.83,22.83 c12.61,0,22.83-10.22,22.83-22.83v-50.01C319.09,91.23,308.87,81.01,296.26,81.01z" />
                    <circle style={ shade } cx="296.32" cy="35.35" r="22.83" />
                </g>
                <g>
                    <path style={ color } d="M811.68,0c-45.33,0-82.08,36.75-82.08,82.08s36.75,82.08,82.08,82.08c45.33,0,82.08-36.75,82.08-82.08 S857.01,0,811.68,0z M811.68,118.49c-20.11,0-36.42-16.3-36.42-36.42c0-20.11,16.3-36.42,36.42-36.42 c20.11,0,36.42,16.3,36.42,36.42C848.1,102.19,831.8,118.49,811.68,118.49z" />
                    <path style={ color } d="M227.83,68.49C227.83,30.66,197.17,0,159.34,0c-17.45,0-33.33,6.58-45.43,17.33C101.82,6.58,85.94,0,68.49,0 C30.66,0,0,30.66,0,68.49v26.3v30.05v16.48c0,12.61,10.22,22.83,22.83,22.83s22.83-10.22,22.83-22.83v-16.48V94.79v-26.3 c0-12.61,10.22-22.83,22.83-22.83s22.83,10.22,22.83,22.83v26.3v30.05v16.48c0,12.61,10.22,22.83,22.83,22.83 s22.83-10.22,22.83-22.83v-16.48V94.79v-26.3c0-12.61,10.22-22.83,22.83-22.83c12.61,0,22.83,10.22,22.83,22.83V92.5 c-0.08,0.76-0.23,1.5-0.23,2.29v46.53c0,12.61,10.22,22.83,22.83,22.83c12.61,0,22.83-10.22,22.83-22.83V94.79 c0-0.78-0.15-1.52-0.23-2.29V68.49z" />
                    <path style={ color } d="M592.52,68.49C592.52,30.66,561.86,0,524.03,0c-17.45,0-33.33,6.58-45.43,17.33 C466.51,6.58,450.63,0,433.18,0c-37.82,0-68.49,30.66-68.49,68.49v26.3v30.05v16.48c0,12.61,10.22,22.83,22.83,22.83 c12.61,0,22.83-10.22,22.83-22.83v-16.48V94.79v-26.3c0-12.61,10.22-22.83,22.83-22.83c12.61,0,22.83,10.22,22.83,22.83v26.3 v30.05v16.48c0,12.61,10.22,22.83,22.83,22.83c12.61,0,22.83-10.22,22.83-22.83v-16.48V94.79v-26.3 c0-12.61,10.22-22.83,22.83-22.83s22.83,10.22,22.83,22.83V92.5c-0.08,0.76-0.23,1.5-0.23,2.29v46.53 c0,12.61,10.22,22.83,22.83,22.83s22.83-10.22,22.83-22.83V94.79c0-0.78-0.15-1.52-0.23-2.29V68.49z" />
                    <path style={ color } d="M661.12,68.49c-12.61,0-22.83,10.22-22.83,22.83v50.01c0,12.61,10.22,22.83,22.83,22.83 s22.83-10.22,22.83-22.83V91.32C683.95,78.71,673.73,68.49,661.12,68.49z" />
                    <circle style={ color } cx="661.18" cy="22.83" r="22.83" />
                    <path style={ color } d="M296.26,68.49c-12.61,0-22.83,10.22-22.83,22.83v50.01c0,12.61,10.22,22.83,22.83,22.83 c12.61,0,22.83-10.22,22.83-22.83V91.32C319.09,78.71,308.87,68.49,296.26,68.49z" />
                    <circle style={ color } cx="296.32" cy="22.83" r="22.83" />
                </g>
            </g>
        </svg>
    );
}
function Index() {
  return (
    <main>
      <Helmet>
        <title>mimio.se</title>
      </Helmet>
      <h1>
          <Logo />
      </h1>
    </main>
  );
}

export default Index;
